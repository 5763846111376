<template>
  <div id="pool-service">
    <Jumbotron
      header="Pools - Service"
      lead="Spend more time enjoying your pool, and less time worrying about it."
      bgImagePath="/media/static/water-wallpaper.jpg"
    >
    </Jumbotron>

    <b-container>
      <b-row>
        <b-col md="4">
          <b-link
            href="https://blog.intheswim.com/10-steps-how-to-winterize-an-in-ground-pool/"
            ><b-img
              thumbnail
              fluid
              src="../../assets/images/winterize-pool.jpg"
            ></b-img
          ></b-link>
        </b-col>
        <b-col md="8">
          <h2 class="title">
            Give Us Your To-Do List<span class="ml-3"
              ><i class="fas fa-clipboard-list"></i
            ></span>
          </h2>
          <p class="long-text">
            Properly maintaining your pool is an important aspect to
            pool-ownership, and we're here to help. From spring to winter, we
            can help you open, maintain, and close your pool while giving you a
            piece of mind that it was done right.
            <b-link href="/contact"> Call or message</b-link> us today for a
            free estimate.
          </p>
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col>
          <h2 class="title">
            <span class="mr-3"><i class="fas fa-tools"></i></span>Popular
            Services
          </h2>
          <p class="long-text">
            Here's a list of services that are most commonly asked for, But
            don't stop there! Over the years we've serviced just about
            everything you can imagine related to a pool. Just remember, we are
            here to make pool-ownership a little easier.
          </p>
          <ul class="fa-ul bullets">
            <li>
              <span class="fa-li" style="color:#307181"
                ><i class="fas fa-thumbtack"></i
              ></span>
              Pool Cover Installation
            </li>
            <li>
              <span class="fa-li" style="color:#307181"
                ><i class="fas fa-thumbtack"></i
              ></span>
              Pool Inspections
            </li>
            <li>
              <span class="fa-li" style="color:#307181"
                ><i class="fas fa-thumbtack"></i
              ></span>
              Seasonal Opening & Closing (Winterizing)
            </li>
            <li>
              <span class="fa-li" style="color:#307181"
                ><i class="fas fa-thumbtack"></i
              ></span>
              Equipment Service/Repair/Replacement
            </li>
            <li>
              <span class="fa-li" style="color:#307181"
                ><i class="fas fa-thumbtack"></i
              ></span>
              Liner Repair/Replacement
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-5">
      <b-row class="text-center">
        <b-col>
          <h2 class="title">
            Gallery<span class="ml-3"><i class="fas fa-images"></i></span>
          </h2>
        </b-col>
      </b-row>
      <b-col>
        <p>
          We know a picture is worth a thousand words. So here's a few of our
          past projects that we've enjoyed so much that we wanted to share with
          you. We hope to show yours too one day (with your permission of
          course!).
        </p>
      </b-col>
      <b-row>
        <b-col v-for="image in images" :key="image.id" md="3" class="py-3">
          <b-img thumbnail fluid :src="image.path"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { HTTP } from "../../http-common";
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  name: "Service",

  components: {
    Jumbotron
  },

  data() {
    return {
      images: []
    };
  },

  mounted: function() {
    this.$nextTick(function() {
      HTTP.get("/pools/images/service").then(response => {
        var payload = response.data;
        this.images = payload;
      });
    });
  },

  methods: {}
};
</script>

<style scoped lang="scss">

.long-text {
  font-size: $base-font-size * 0.9;
  font-family: "Lato", sans-serif;
}

.title {
  color: #307181;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

a {
  color: #307181;

  &:hover {
    text-decoration: none;
    font-weight: bold;
  }
}

.bullets {
  font-size: $base-font-size * 0.85;
}
</style>
